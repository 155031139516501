import { SyntheticEvent, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabPanel } from 'react-ui-kit-exante';

import { PAGES } from 'constants/pages';
import { useQuery } from 'hooks';

import { Client } from './Client';
import { Factor } from './Factor';
import { ACTIVE_TAB, Tabs } from './constants';
import {
  TabsContainer,
  TabsHeader,
  TabsHeaderTitle,
  TabsWrapper,
} from './styled';

export const StressTestResult = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(ACTIVE_TAB);

  const query = useQuery();

  const handleChange = (_: SyntheticEvent, newValue: unknown) => {
    const jobId = query.get('jobId');

    setSelectedTab(newValue as number);

    if (!jobId) {
      return;
    }

    if (Tabs.Client === newValue) {
      const params = createSearchParams({
        jobId,
        groupBy: 'account_id',
      }).toString();
      const path = `${location.pathname}?${params}`;

      navigate(path, {
        replace: true,
      });

      return;
    }

    const params = createSearchParams({
      jobId,
      groupBy: 'underlying',
    }).toString();
    const path = `${location.pathname}?${params}`;

    navigate(path, {
      replace: true,
    });
  };

  useEffect(() => {
    if (query.get('jobId')) {
      if (query.get('groupBy') === 'underlying') {
        setSelectedTab(Tabs.Factor);
      } else if (query.get('groupBy') === 'account_id') {
        setSelectedTab(Tabs.Client);
      }
    }
  }, []);

  return (
    <TabsWrapper>
      <TabsHeader>
        <TabsHeaderTitle>{PAGES.stressTestResult}</TabsHeaderTitle>
      </TabsHeader>
      <TabsContainer value={selectedTab} onChange={handleChange}>
        <Tab label={PAGES.resultVarPerFactor} />
        <Tab label={PAGES.resultVarPerClient} />
      </TabsContainer>
      <TabPanel value={selectedTab} index={Tabs.Factor}>
        <Factor />
      </TabPanel>
      <TabPanel value={selectedTab} index={Tabs.Client}>
        <Client />
      </TabPanel>
    </TabsWrapper>
  );
};
