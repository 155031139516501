import {
  SyntheticEvent,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Tab, TabPanel } from 'react-ui-kit-exante';

import { PAGES } from 'constants/pages';

import { ManualTest } from './ManualTest';
import { State } from './State';
import { ACTIVE_TAB, JobsTabs } from './constants';
import {
  StressTestJobsContext,
  StressTestJobsReducer,
  initialState,
  reducer,
} from './context';
import {
  TabsContainer,
  TabsHeader,
  TabsHeaderTitle,
  TabsWrapper,
} from './styled';

type LocationStateProps = {
  state: Record<string, unknown>;
};

export const StressTestJobs = () => {
  const { state: locationState }: LocationStateProps = useLocation();
  const [state, dispatch] = useReducer<StressTestJobsReducer>(
    reducer,
    initialState,
  );
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const [selectedTab, setSelectedTab] = useState<number>(ACTIVE_TAB);

  const handleChange = (_: SyntheticEvent, newValue: unknown) => {
    setSelectedTab(newValue as number);
  };

  useEffect(() => {
    if (typeof locationState?.activeTab === 'number') {
      setSelectedTab(locationState.activeTab);

      window.history.replaceState({}, document.title);
    }
  }, [locationState]);

  return (
    <StressTestJobsContext.Provider value={contextValue}>
      <Routes>
        <Route
          path=""
          element={
            <TabsWrapper>
              <TabsHeader>
                <TabsHeaderTitle>{PAGES.stressTestJobs}</TabsHeaderTitle>
              </TabsHeader>
              <TabsContainer value={selectedTab} onChange={handleChange}>
                <Tab label={PAGES.jobsManualTest} />
                <Tab label={PAGES.jobsStressJobsState} />
              </TabsContainer>
              <TabPanel value={selectedTab} index={JobsTabs.ManualTest}>
                <ManualTest />
              </TabPanel>
              <TabPanel value={selectedTab} index={JobsTabs.State}>
                <State />
              </TabPanel>
            </TabsWrapper>
          }
        />
      </Routes>
    </StressTestJobsContext.Provider>
  );
};
