import { SyntheticEvent, useMemo, useReducer, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Tab, TabPanel } from 'react-ui-kit-exante';

import { PAGES } from 'constants/pages';

import { Exclude } from './Exclude';
import { ExcludeAdd } from './ExcludeAdd';
import { Parameters } from './Parameters';
import { ParametersAdd } from './ParametersAdd';
import { RuntimeSettingAdd } from './RuntimeSettingAdd';
import { RuntimeSettings } from './RuntimeSettings';
import { SegregatedAccountAdd } from './SegregatedAccountAdd';
import { SegregatedAccounts } from './SegregatedAccounts';
import { ACTIVE_TAB, Tabs } from './constants';
import {
  StressTestSettingsContext,
  StressTestSettingsReducer,
  initialState,
  reducer,
} from './context';
import { StressTestSettingsRoutes } from './routes';
import {
  TabsContainer,
  TabsHeader,
  TabsHeaderTitle,
  TabsWrapper,
} from './styled';

export const StressTestSettings = () => {
  const [state, dispatch] = useReducer<StressTestSettingsReducer>(
    reducer,
    initialState,
  );
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const [selectedTab, setSelectedTab] = useState(ACTIVE_TAB);

  const handleChange = (_: SyntheticEvent, newValue: unknown) => {
    setSelectedTab(newValue as number);
  };

  return (
    <StressTestSettingsContext.Provider value={contextValue}>
      <Routes>
        <Route
          path=""
          element={
            <TabsWrapper>
              <TabsHeader>
                <TabsHeaderTitle>{PAGES.stressTestSettings}</TabsHeaderTitle>
              </TabsHeader>
              <TabsContainer value={selectedTab} onChange={handleChange}>
                <Tab label={PAGES.stressParameters} />
                <Tab label={PAGES.excludeFromStress} />
                <Tab label={PAGES.segregatedClients} />
                <Tab label={PAGES.runtimeSettings} />
              </TabsContainer>
              <TabPanel value={selectedTab} index={Tabs.StressParameters}>
                <Parameters />
              </TabPanel>
              <TabPanel value={selectedTab} index={Tabs.ExcludeFromStress}>
                <Exclude />
              </TabPanel>
              <TabPanel value={selectedTab} index={Tabs.SegregatedClients}>
                <SegregatedAccounts />
              </TabPanel>
              <TabPanel value={selectedTab} index={Tabs.RuntimeSettings}>
                <RuntimeSettings />
              </TabPanel>
            </TabsWrapper>
          }
        />
        <Route
          path={StressTestSettingsRoutes.STRESS_PARAMETERS_ADD}
          element={<ParametersAdd />}
        />
        <Route
          path={StressTestSettingsRoutes.STRESS_EXCLUDE_ADD}
          element={<ExcludeAdd />}
        />
        <Route
          path={StressTestSettingsRoutes.SEGREGATED_ACCOUNT_ADD}
          element={<SegregatedAccountAdd />}
        />
        <Route
          path={StressTestSettingsRoutes.RUNTIME_SETTING_ADD}
          element={<RuntimeSettingAdd />}
        />
      </Routes>
    </StressTestSettingsContext.Provider>
  );
};
